import React, {
    Component
  } from 'react';
  import './App.css';
  import axios from 'axios';
  import EmailForm from './components/EmailForm';
  import Table from './components/Table';
  import Filter from './components/Filter';
  import ModalWindow from './components/Modal';
  
  class App extends Component {
    constructor(props) {
      super(props);
      this.modalIsOpen = false;
      this.state = {
        allItems: [],
        renderItems: [],
        filterValue: 2,
        modalIsOpen: this.modalIsOpen,
        titleWindow: '',
        textWindow: '',
        checkedItems: [], 
        flag: 3
      }
      this.openWindow = this.openWindow.bind(this);
      this.handleForm = this.handleForm.bind(this);
      this.wialonReq = this.wialonReq.bind(this);
      this.filterItems=this.filterItems.bind(this);    
      this.selectItems = this.selectItems.bind(this);
    }


   convertTime(unix) {
        var date = new Date(unix * 1000);
        var year = date.getFullYear();
        var day = date.getDate();
        if (("" + day).length === 1) {
            day = "0" + day;
        }
        var month = date.getMonth() + 1;
        if (("" + month).length === 1) {
            month = "0" + month;
        }
        var hours = date.getHours()+'';
        if(hours.length===1){
            hours='0'+hours;
        }
        var minutes = date.getMinutes()+'';
        if(minutes.length===1){
            minutes='0'+minutes;
        }
        var seconds = date.getSeconds()+'';
        if(seconds.length===1){
            seconds='0'+seconds;
        }
        var formattedDate = day + "." + month + "." + year + " " + hours + ":" + minutes + ":" + seconds;
        return formattedDate;
    }
    
    differentBetweenCurrAndLastMsgTime(lastMsgTime, filter) {
        var date = new Date().getTime() / 1000;
        var diff = Math.round(date - lastMsgTime);
        var min, hours, days;
        if (diff > 60) {
            min = Math.floor(diff / 60);
            if (min > 60) {
                hours = Math.floor(min / 60);
                min = Math.floor(min-hours*60)
                if (hours > 24) {
                    days = Math.floor(hours / 24);
                    hours = Math.floor(hours-days* 24);
                    if (days >= filter) {
                        return days + " дн. " + hours + " ч. " + min + " мин."
                    } else {
                        return false;
                    }
                }
            }
        }
        return '';
    }


    componentDidMount(){
      this.wialonReq(this.state.filterValue);
    }

    wialonReq() {
      const searchUrl = window.location.search;
      const sid = searchUrl.slice(searchUrl.indexOf('=') + 1, searchUrl.indexOf('&'));
      if (sid) {
        axios.post('/wialon', {
            sid: sid
          })
          .then((response) => {
            if (typeof (response.data) == 'object' && response.data !== 0) {
              this.setState({
                allItems: response.data
              },
            function(){
                this.filterItems(this.state.allItems, this.state.filterValue);
            });
            } else if (response.data === 0) {
              this.setState({
                allItems: []
              });
              this.handleForm(true, '', 'Отсутствуют объекты, которые не на связи более суток')
            } else {
              this.setState({
                allItems: []
              });
              this.handleForm(true, 'Внимание', 'Сессия не действительная. Заново войдите в приложение')
            }
          })
      } else {
        this.setState({
            modalIsOpen:true,
            titleWindow: 'Внимание',
            textWindow: 'Сессия не действительная. Попробуйте еще раз зайти в приложение'
        });
      }
    }

    filterItems(items, filter){
        var itemsArr = [];
        var timeMsg, diffTime;
        for (var i = 0; i < items.length; i++) {
            if (items[i].lmsg === null) {
                itemsArr.push({
                    "id": i,
                    "name": items[i].nm,
                    "lastMsgTime": '-',
                    "convertTime": 'Нет сообщений',
                    "differentTime": '-',
                    "checked": false,
                    "commit": ""
                })
            } else {
                timeMsg = items[i].lmsg.t;
                if (this.differentBetweenCurrAndLastMsgTime(timeMsg, filter) !== false && this.differentBetweenCurrAndLastMsgTime(timeMsg, filter) !== "") {
                    diffTime = this.differentBetweenCurrAndLastMsgTime(timeMsg, filter);
                    itemsArr.push({
                        "id":i,
                        "name": items[i].nm,
                        "lastMsgTime": timeMsg,
                        "convertTime": this.convertTime(timeMsg),
                        "differentTime": diffTime,
                        "checked": false,
                        "commit": ""
                    })
                }
            }
        }
        for(var j=0; j<itemsArr.length; j++){
            itemsArr[j]['id']=j;
        }

        this.setState({
            renderItems: itemsArr
        })
    }
    
    handleForm(modalOpenValue, titleWindow, textWindow) {
      this.setState({
        modalIsOpen: modalOpenValue,
        titleWindow: titleWindow,
        textWindow: textWindow
      })
    }
  
    openWindow(modalIsOpen) {
      this.setState({
        modalIsOpen: modalIsOpen
      })
    }
  
    selectItems(items) { 
      this.setState({
        allItems: items
      })
    }

  render() { 
    return (
      <div className="wrap-table">
          <Filter
            filterItems={this.filterItems}
            items={this.state.allItems}
            //renderItems={this.state.renderItems}
            filterValue={this.state.filterValue}
            flag={this.state.flag}
          />
          <Table
            pageItems={this.state.renderItems}
            selectItems={this.selectItems}
          />
        
          <EmailForm  
              onChangeForm = {this.handleForm}
              items={this.state.renderItems}
          />
          <ModalWindow
              window={this.openWindow}
              modalIsOpen={this.state.modalIsOpen}
              title={this.state.titleWindow}
              text={this.state.textWindow}
          />
      </div>
    );
  }
}

export default App;

