import React, {Component} from 'react';
const $ = window.$;

class Filter extends Component{
    constructor(props) {
        super(props);
        this.state = {
            filterValue: this.props.filterValue,
            visibiltyFilterError: 'hidden',
            disableFilter: true
        }
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.onUpdateTable = this.onUpdateTable.bind(this);
    }
    
    onChangeFilter(event) {
        this.setState({
                filterValue: event.target.value
            },
            function () {
                if (isNaN(Number(this.state.filterValue)) || Number(this.state.filterValue) === 0 || Number(this.state.filterValue) > 10000) {
                    this.setState({
                        visibiltyFilterError: 'visible',
                        disableFilter: true
                    })
                    $('.error-filtervalue').css('height', 'auto');
                    $(".btn-filter").css('cursor', 'not-allowed');
                    $(".btn-filter").css('opacity', 0.6);  
                } else {
                    this.setState({
                        disableFilter: false,
                        visibiltyFilterError: 'hidden'
                    });
    
                    $('.error-filtervalue').css('height', 0);
                    $(".btn-filter").css('cursor', 'pointer');
                    $(".btn-filter").css('opacity', 1);
                }
            })
    }
    onUpdateTable(event) {
        event.preventDefault();
        this.props.filterItems(this.props.items, this.state.filterValue);
    }

    render(){
        return(
        <div className='wrap-settings'>   
            <form>
                <label>
                <span className="glyphicon glyphicon-cog"></span>  Фильтр по дням: 
                <input 
                        type="text" 
                        id="filter-value" 
                        name="filter-value" 
                        style={{'marginLeft': '10px'}}
                        onChange={this.onChangeFilter} 
                        value={this.state.filterValue} />
                </label>
                <button 
                        className="btn-filter btn-primary" 
                        onClick={this.onUpdateTable} 
                        disabled={this.state.disableFilter}>Применить
                </button>
                <br/>
            
            </form>
            <div 
                style={{visibility: this.state.visibiltyFilterError}} 
                className="error-filtervalue">
                Значение фильтра не должно превышать 10000 и должно быть представлено числом
            </div>
        </div>
        )
    }
}

export default Filter;