import React, { Component, Fragment } from "react";
import axios from 'axios';
const $ = window.$;

class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      clientName: "",
      email: "",
      phone: ""
    }
    this.onInputChange=this.onInputChange.bind(this);
    this.onSubmitForm=this.onSubmitForm.bind(this);
  }

  onFormChange() {
    var reg = /^([A-Za-z0-9_\-.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (this.state.clientName.length === 0 || this.state.email.length === 0 || reg.test(this.state.email) === false) {
      this.setState({ disable: true })
    }
    else {
      this.setState({ disable: false })
    }
  }

  onInputChange(event) {
     const name = event.target.name;
     const value = event.target.value;
     this.setState({ [name]: value }, function(){
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
       
       if (name === "email" && reg.test(value) === false) {
            $(".error-" + name).css('visibility', 'visible');
            $(".error-" + name).css('height', 'auto');
      
         this.onFormChange();
       }
       else if (name === "email") {
        $(".error-" + name).css('visibility', 'hidden');
        $(".error-" + name).css('height', 0);
        this.onFormChange();
       }
   
       if (name === "clientName" && value.length === 0) {
        $(".error-" + name).css('visibility', 'visible');
        $(".error-" + name).css('height', 'auto');
         this.onFormChange();
       }
       else if (name === "clientName") {
        $(".error-" + name).css('visibility', 'hidden');
        $(".error-" + name).css('height', 0);
        this.onFormChange();
       } 
     })
   }

   onSubmitForm(event) {
    event.preventDefault();
    const client = $('.clientName').val();
    const email = $('.email').val();
    const phone = $('.phone').val();
    const userInfo = {
        'Имя': client,
        'Эл.почта': email,
        'Конт.телефон': phone
    }
  
    let arr = [];
    let counter = 0;
    const data=this.props.items;
   for(let i=0; i<data.length; i++){
       if(data[i].checked){
        arr.push({
            'item': data[i].name,
            'diffTime':  data[i].differentTime,
            'convertTime': data[i].convertTime,
            'commit': data[i].commit
        })
        counter++;
       }
   }
    
    if (counter === 0) {
        this.handleFormChange(true, 'Внимание', 'Для отправки формы необходимо выбрать хотя бы один объект.');
       
    } else { 
        let data = {
            userInfo: userInfo,
            objects: arr
        }

        axios.post("/send", {
                data: JSON.stringify(data)
            })
            .then(responce => {   
                this.handleFormChange(true, 'Внимание', responce.data);
            });     
    }
}

handleFormChange = (state, title, text) => {
    this.props.onChangeForm(state, title, text);            
}

  render() {
    return (
      <Fragment>
      <form id="register-form" className="register-form">
      <p className='add-info'>** Для упрощения обработки запроса можете оставить комментарии о текущем состоянии авто (напр., отключена масса, в ремонте, в движении).</p>
          <h3>Заполните форму отправки</h3>
          <div className='row wrap-form'>
          <label><span>* Ваше имя:</span>  
              <input 
                  style={{'marginLeft':'10px'}}
                  className="clientName" 
                  name="clientName" 
                  type="text"
                  value={this.state.clientName} 
                  onChange={this.onInputChange}
                />
          
          </label>
          <span className="error-clientName">
          * Данное поле должно быть заполнено
          </span>
          <label> <span>* Ваша электронная почта:</span> 
              <input 
                  className="email" 
                  name="email" 
                  type="text"
                  value={this.state.email} 
                  onChange={this.onInputChange}
                  style={{'marginLeft':'10px'}} 
              />
            
          </label>
         
          <span className="error-email">
          * Введите значение след.вида: ivanova@gmail.com  
          </span>
          <label> Контактный телефон: 
              <input 
                  className="phone" 
                  name="phone" 
                  type="tel"
                  value={this.state.phone} 
                  onChange={this.onInputChange}
                  style={{'marginLeft':'10px'}} 
                />
            </label>
          <input 
                  type="submit" 
                  onClick={this.onSubmitForm} 
                  name="btn-submit" 
                  className='btn btn-primary btn-submit' 
                  value="Отправить" 
                  disabled={this.state.disable} 
            />
            </div>
           
        </form>
       </Fragment>
    );
  }
}

export default EmailForm;
