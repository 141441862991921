import React, {
  Component
} from "react";
import ReactTable from "react-table";
import matchSorter from 'match-sorter'
import 'react-table/react-table.css'

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      target: '',
      selected: {},
      selectAll: 0
    }

    this.renderEditableTextarea = this.renderEditableTextarea.bind(this);
    this.toggleRow=this.toggleRow.bind(this);
    this.toggleSelectAll=this.toggleSelectAll.bind(this);
  }
  
  renderEditableTextarea(cellInfo) {
    return (
    <div style = {
        {
          backgroundColor: "white"
        }
      }
      contentEditable suppressContentEditableWarning onBlur = {
        event => {
          this.setState({
            data: this.props.pageItems,
            target: event.target
          }, function () {
            const data = [...this.state.data];
            data[cellInfo.index][cellInfo.column.id] = this.state.target.innerHTML;
            this.setState({
              data
            });
          })

        }
      }
      dangerouslySetInnerHTML = {
        {
          __html: this.props.pageItems[cellInfo.index][cellInfo.column.id]
        }
      }
      />
    );
  }


  toggleRow(name, commit) {
    const data=this.props.pageItems;
    for(let i=0; i<data.length; i++){
      if(data[i].name===name){
        data[i].checked=!data[i].checked;
        data[i].commit=commit
      }
    }
    this.props.selectItems(data);
    const newSelected = Object.assign({}, this.state.selected);
		newSelected[name] = !this.state.selected[name];
		this.setState({
			selected: newSelected,
			selectAll: 2
		});
	}

	toggleSelectAll(commit) {
		let newSelected = {};
		if (this.state.selectAll === 0) {
			this.props.pageItems.forEach(item => {    
				newSelected[item.name] = true;
			});
		}
		this.setState({
			selected: newSelected,
			selectAll: this.state.selectAll === 0 ? 1 : 0
    },
  function(){
    const data=this.props.pageItems;
    if(this.state.selectAll===1){
     
      for(let i=0; i<data.length; i++){
          data[i].checked=true;
          data[i].commit=commit
        }
      this.props.selectItems(data);
    }
    else{
      for(let i=0; i<data.length; i++){
        data[i].checked=false;
      }
    this.props.selectItems(data);
    }
  });
	}

  render() {
    const columns = [
     	{
          id: "checkbox",
          accessor: "",
          filterable: false,
          minResizeWidth: 40,
          maxWidth: 70,
          minWidth:30,
          Cell: ({ original }) => {
            return (
              <input
               
                type="checkbox"
                className="checkbox"
                checked={this.state.selected[original['name']] === true}
                onChange={() => this.toggleRow(original.name, original.commit)}
              />
            );
          },
          Header: x => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                checked={this.state.selectAll === 1}
                ref={input => {
                  if (input) {
                    input.indeterminate = this.state.selectAll === 2;
                  }
                }}
                onChange={() => this.toggleSelectAll(x.commit)}
              />
            );
          },
          sortable: false,
      },
      {
      Header: 'Имя объекта',
      accessor: 'name',
      id: 'name',
      className: 'name',
      
      minResizeWidth: 10,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["name"]
        }),
      filterAll: true
    }, {
      Header: 'Нет на связи',
      accessor: 'differentTime',
      id: 'differentTime',
      className: 'differentTime',
      minResizeWidth: 10,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["differentTime"]
        }),
      filterAll: true,
      sortMethod: (a, b) => {
        var parseA, parseB, A, B;
        if (a === '-') {
          parseA = 10000000000;
        } else {
          A = a.replace(' дн.', '').replace(' ч.', '').replace(' мин.', '').split(' ');
          parseA = (+A[0]) * 24 * 60 * 60 + (+A[1]) * 60 * 60 + (+A[2]) * 60;
        }

        if (b === '-') {
          parseB = 10000000000;
        } else {
          B = b.replace(' дн.', '').replace(' ч.', '').replace(' мин.', '').split(' ');
          parseB = (+B[0]) * 24 * 60 * 60 + (+B[1]) * 60 * 60 + (+B[2]) * 60;
        }

        if (parseA > parseB) {
          return 1;
        } else if (parseA < parseB) {
          return -1;
        }
        return 0;
      }

    }, {
      Header: 'Время последнего сообщения', // Custom header components!
      accessor: 'convertTime',
      id: 'convertTime',
      className: 'convertTime',
      minResizeWidth: 10,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["convertTime"]
        }),
      filterAll: true,
      sortMethod: (a, b) => {
        var parseA, parseB;
        if (a === 'Нет сообщений') {
          parseA = 0;
        } else {
          parseA = new Date(a.split(' ')[0].split('.').reverse().join('-') + 'T' + a.split(' ')[1]).getTime() / 1000;
        }

        if (b === 'Нет сообщений') {
          parseB = 0;
        } else {
          parseB = new Date(b.split(' ')[0].split('.').reverse().join('-') + 'T' + b.split(' ')[1]).getTime() / 1000;
        }
        return (parseA + '').localeCompare(parseB + '');
      }
    }, {
      Header: '** Комментарий', // Custom header components!
      id: 'commit',
      className: 'commit',
      filterable: false,
      Cell: this.renderEditableTextarea,
      sortable: false,
      minResizeWidth: 10
    }]

    return ( <ReactTable data = {
        this.props.pageItems
      }
      className = "-striped -highlight"
      defaultPageSize={10}
      noDataText="Загрузка..."
      columns = {
        columns
      }
      previousText={<span className='glyphicon glyphicon-chevron-left'></span>}
      nextText={<span className='glyphicon glyphicon-chevron-right'></span>}
      pageText='Стр.'
      ofText='из'
      rowsText='строк'
      filterable defaultFilterMethod = {
        (filter, row) =>
        String(row[filter.id]) === filter.value
      }
      />
    )
  }
}

export default Table;