import React from 'react';
import Modal from 'react-modal';
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class ModalWindow extends React.Component {
  constructor(props) {
    super(props);
   
   this.closeModal=this.closeModal.bind(this);
   this.afterOpenModal=this.afterOpenModal.bind(this);
  }

  openModal() {
    this.props.window(true)
  }
 
  afterOpenModal() {
    this.subtitle.style.color = 'darkblue';
    this.subtitle.style.fontSize='24px';
  }
 
  closeModal() {
    this.props.window(false)
  }
 
 
  render() {
    return (
      <Modal
          isOpen={this.props.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
      >
      <div className="modal-window" >
          <div ref={subtitle => this.subtitle = subtitle}>
            <span className='glyphicon glyphicon-alert'></span>
            <span>  {this.props.title}</span> 
            <span 
              style={{float:'right', cursor: 'pointer'}}  
              onClick={this.closeModal} 
              className='glyphicon glyphicon-remove-circle'>
            </span>
          </div>
          <div style={{padding:'15px', textAlign: 'center'}}>{this.props.text}</div>
      </div>
      </Modal>
    );
  }
}
Modal.setAppElement('body');    //Для избежания ошибки!!! Warning: react-modal: App element is not defined. Please use `Modal.setAppElement                                     (el)` or set `appElement={el}`.

export default ModalWindow;